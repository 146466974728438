import React, { useState } from "react"
import style from "./accordion.mod.scss"
import PropTypes from "prop-types"

const Accordion = ({ title, children, openInitially, modifier }) => {
  const [isOpen, setOpen] = useState(openInitially)
  return (
    <div data-is-open={isOpen} className={[style.toggle, modifier].join(" ")}>
      <button className={style.toggle__head} onClick={() => setOpen(!isOpen)}>
        {title}
        <span className={style.toggle__arrow}></span>
      </button>
      <div className={style.toggle__content} children={children} />
    </div>
  )
}

export default Accordion

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  openInitially: PropTypes.bool,
  modifier: PropTypes.string
}
