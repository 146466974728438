import React from "react"
import style from "./table.mod.scss"
import PropTypes from "prop-types"

const Table = ({
  head = [],
  body = [],
  foot = [],
  firstColumnHeader = false,
  modifier = ""
}) => (
  <div className={[style.table, `${style.table}--${modifier}`].join(" ")}>
    <div className={style.table__inner}>
      <table>
        {head.length > 0 && (
          <thead>
            <tr>
              {head.map((label, i) => (
                <th
                  className={style.table__head}
                  key={`head-${i}`}
                  aria-label={`table-${i}`}
                  children={label}
                />
              ))}
            </tr>
          </thead>
        )}

        {body.length > 0 && (
          <tbody>
            {body.map((row, i) => (
              <tr key={`row-${i}`}>
                {row.map((cell, i) =>
                  firstColumnHeader && i === 0 ? (
                    <th key={`cell-${i}`} scope="row">
                      {cell}
                    </th>
                  ) : (
                    <td key={`cell-${i}`}>{cell}</td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        )}

        {foot.length > 0 && (
          <tfoot>
            <tr>
              {head.map((label, i) => (
                <td
                  className={style.table__head}
                  key={`foot-${i}`}
                  children={label}
                />
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  </div>
)

export default Table

Table.propTypes = {
  head: PropTypes.array,
  body: PropTypes.array,
  foot: PropTypes.array,
  firstColumnHeader: PropTypes.bool,
  modifier: PropTypes.string
}
