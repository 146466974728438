import React from "react"
import style from "./button.mod.scss"
import { Link, graphql } from "gatsby"
import ExternalLinkIcon from "@svgs/externalLink.svg"
import Arrow from "@svgs/arrow-right.svg"
import Dload from "@svgs/download-icon.svg"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"
import CompletedTick from "@svgs/completed-tick.svg"

const Button = ({
  to = "/",
  text = "More",
  type = null,
  url = false,
  onClick,
  secondary = false,
  tertiary = false,
  completed = false,
  maxWidth = false,
  size,
  modifiers = [],
  disabled,
  arrow = false,
  align = "center",
  shrink = false,
  noExternalIcon = false,
  image = false,
  reverseContent = false,
  justifyContentStart = false,
  download = false
}) => {
  if (url) {
    to = url
    console.info(`url on button is deprecated. Update`)
  }

  const location = useLocation()

  secondary && modifiers.push(`${style.button}--secondary`)
  tertiary && modifiers.push(`${style.button}--tertiary`)
  completed && modifiers.push(`${style.button}--completed`)
  align && modifiers.push(`${style.button}--${align}`)
  size && modifiers.push(`${style.button}--${size}`)
  shrink && modifiers.push(`${style.button}--shrink`)
  maxWidth && modifiers.push(`${style.button}--max-width`)
  reverseContent && modifiers.push(`${style.button}--reverse-content`)
  download && modifiers.push(`${style.button}--download`)
  justifyContentStart &&
    modifiers.push(`${style.button}--justify-content-start`)

  const isExternal = to.search(/http/g) > -1

  const ButtonContent = () => (
    <React.Fragment>
      <span className={style.button__text}>{text}</span>
      {isExternal && !noExternalIcon && (
        <span className={style.button__icon}>
          <ExternalLinkIcon />
        </span>
      )}
      {arrow && (
        <span className={style.button__icon}>
          <Arrow />
        </span>
      )}
      {download && (
        <span className={style.button__icon}>
          <Dload />
        </span>
      )}
      {completed && <CompletedTick />}
      {image && <span className={style.button__image}>{image}</span>}
    </React.Fragment>
  )

  const config = {
    className: [style.button, ...modifiers].join(" "),
    onClick
  }

  if (disabled) config.disabled = "disabled"

  if (isExternal) {
    return (
      <a href={to} {...config} target="_blank" rel="noopener noreferrer">
        <ButtonContent />
      </a>
    )
  } else if (type) {
    return (
      <button type={type} {...config}>
        <ButtonContent />
      </button>
    )
  } else {
    return (
      <Link to={to} {...config} state={{ referrer: location.pathname }}>
        <ButtonContent />
      </Link>
    )
  }
}

export default Button

Button.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  size: PropTypes.string,
  modifiers: PropTypes.array
}

export const query = graphql`
  fragment Button on DatoCmsButton {
    model {
      apiKey
    }
    id
    link {
      ...LinkHelper
    }
  }
  fragment Pdf on DatoCmsPdfDownload {
    model {
      apiKey
    }
    id
    buttonText
    pdf {
      url
    }
  }
`
