import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import style from "./mealPlan.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Accordion from "@elements/Accordion"
import Button from "@elements/Button"
import Block from "@global/layout/Block"
import { mealPlanBody, dayColumns } from "@helpers/mealplanHelpers"
import PropTypes from "prop-types"
import Content from "@elements/Content"
import Table from "@elements/Table"

const MealPlan = ({
  title,
  banner,
  descriptionNode,
  pdfMealPlan,
  pdfGroceryList,
  mealPlanWeeks
}) => {
  const text = useStaticQuery(graphql`
    {
      content: contentYaml(language: { eq: "en-NZ" }) {
        ...MealPlanContent
      }
    }
  `)

  const { grocery, mealPlan } = text.content.mealPlanPdfs

  const linksArr = []
  pdfMealPlan && linksArr.push({ url: pdfMealPlan.url, text: mealPlan })
  pdfGroceryList && linksArr.push({ url: pdfGroceryList.url, text: grocery })

  const weeks = mealPlanWeeks.map(week => ({
    id: week.id,
    title: week.weekNumber,
    description: week.descriptionNode.childMarkdownRemark.html,
    table: {
      head: [null, ...dayColumns(text.content.dayNames)],
      body: mealPlanBody(week.weekRows)
    },
    note: week.noteNode.childMarkdownRemark.html
  }))

  return (
    <div className={style.mealPlan}>
      <GatsbyImage
        className={style.mealPlan__image}
        image={banner.gatsbyImageData}
      />
      <Block gutters={true} maxWidth="list-inner">
        <div className={style.mealPlan__content}>
          <h1 className={style.mealPlan__title}>{title}</h1>
          {descriptionNode && (
            <Content html={descriptionNode.childMarkdownRemark.html} />
          )}
          <Block padding="double" desktopFlex={true}>
            {linksArr.length > 0 &&
              linksArr.map(
                (link, i) =>
                  link && (
                    <Button
                      url={link.url}
                      text={link.text}
                      key={`link-${i}`}
                      margin="bottom"
                    />
                  )
              )}
          </Block>
        </div>
      </Block>
      <div className={style.mealPlan__plan}>
        {weeks.map((week, i) => {
          return (
            <Accordion
              key={`key-${week.id}`}
              title={week.title}
              openInitially={i === 0}
            >
              {week.description && (
                <Block maxWidth="inner" padding="bottom">
                  <Content html={week.description} />
                </Block>
              )}

              <Table
                head={week.table.head}
                body={week.table.body}
                modifier="nested"
              />
              {week.note && (
                <Block maxWidth="inner" padding="bottom">
                  <Content html={week.note} />
                </Block>
              )}
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default MealPlan

MealPlan.propTypes = {
  title: PropTypes.string.isRequired,
  banner: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  pdfMealPlan: PropTypes.object,
  pdfGroceryList: PropTypes.object,
  mealPlanWeeks: PropTypes.array.isRequired
}

export const query = graphql`
  fragment MealPlanContent on ContentYaml {
    mealPlanPdfs {
      grocery
      mealPlan
    }
    dayNames {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }
  fragment MealPlan on DatoCmsMealPlan {
    id
    path
    title
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    descriptionNode {
      childMarkdownRemark {
        html
      }
    }
    banner {
      gatsbyImageData(imgixParams: { w: "1600" })
    }
    pdfMealPlan {
      url
    }
    pdfGroceryList {
      url
    }
    mealPlanWeeks {
      ... on DatoCmsMealPlanWeek {
        id
        title
        weekNumber
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        noteNode {
          childMarkdownRemark {
            html
          }
        }
        model {
          apiKey
        }
      }
      weekRows {
        ... on DatoCmsRow {
          id
          title
          mondayAdditionalText
          mondayRecipeLink {
            id
            path
            title
          }
          tuesdayAdditionalText
          tuesdayRecipeLink {
            id
            path
            title
          }
          wednesdayAdditionalText
          wednesdayRecipeLink {
            id
            path
            title
          }
          thursdayAdditionalText
          thursdayRecipeLink {
            id
            path
            title
          }
          fridayAdditionalText
          fridayRecipeLink {
            id
            path
            title
          }
          saturdayAdditionalText
          saturdayRecipeLink {
            id
            path
            title
          }
          sundayAdditionalText
          sundayRecipeLink {
            id
            path
            title
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
