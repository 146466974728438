import React from "react"
import { Link } from "gatsby"

const MealPlanCell = ({ rowId, links = [], text = "" }) => {
  return (
    <React.Fragment>
      {links.map(({ id, title, path }, i) => (
        <Link key={rowId + id + i} to={path}>
          {title}
        </Link>
      ))}
      {text}
    </React.Fragment>
  )
}

const mealPlanBody = weekRows =>
  weekRows.map(row => {
    const config = mealPlanColumnsConfig(row)

    return config.map(config => <MealPlanCell {...config} />)
  })

const mealPlanColumnsConfig = row => [
  {
    rowId: row.id,
    text: row.title
  },
  {
    rowId: row.id,
    text: row.mondayAdditionalText,
    links: row.mondayRecipeLink
  },
  {
    rowId: row.id,
    text: row.tuesdayAdditionalText,
    links: row.tuesdayRecipeLink
  },
  {
    rowId: row.id,
    text: row.wednesdayAdditionalText,
    links: row.wednesdayRecipeLink
  },
  {
    rowId: row.id,
    text: row.thursdayAdditionalText,
    links: row.thursdayRecipeLink
  },
  {
    rowId: row.id,
    text: row.fridayAdditionalText,
    links: row.fridayRecipeLink
  },
  {
    rowId: row.id,
    text: row.saturdayAdditionalText,
    links: row.saturdayRecipeLink
  },
  {
    rowId: row.id,
    text: row.sundayAdditionalText,
    links: row.sundayRecipeLink
  }
]

const dayColumns = dayNames =>
  Object.values(dayNames).map(column => {
    return column.substring(0, 3)
  })

export { mealPlanColumnsConfig, mealPlanBody, MealPlanCell, dayColumns }
