import React from "react"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import MealPlan from "../components/mealPlans/mealPlan"
import { HelmetDatoCms } from "gatsby-source-datocms"

const MealPlanDetail = ({ data: { mealPlan }, pageContext: { parent } }) => {
  return (
    <Layout parent={parent}>
      <HelmetDatoCms defer={false} seo={mealPlan.seoMetaTags} />
      <MealPlan {...mealPlan} />
    </Layout>
  )
}

export const query = graphql`
  query mealPlanById($id: String) {
    mealPlan: datoCmsMealPlan(id: { eq: $id }) {
      ...MealPlan
    }
  }
`

export default MealPlanDetail
