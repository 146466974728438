import React from "react"
import PropTypes from "prop-types"
import style from "./block.mod.scss"

const Block = ({
  children,
  padding = "none",
  background = "none",
  maxWidth = "none",
  desktopFlex = false,
  gutters = false,
  guttersMobile = false,
  maxHeight = false,
  desktopPadding = "none"
}) => {
  const modifiers = [
    style[
      "block--padding-" +
        padding
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ],
    style[
      "block--desktop-padding-" +
        desktopPadding
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ],
    style[
      "block--bg-" +
        background
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ],
    style[
      "block--max-width-" +
        maxWidth
          .trim()
          .toLowerCase()
          .replace(/ /gi, "-")
    ]
  ]
  gutters && modifiers.push(style["block--gutters"])
  guttersMobile && modifiers.push(style["block--gutters-mobile"])
  desktopFlex && modifiers.push(style["block--desktop-flex"])
  maxHeight && modifiers.push(style["block--max-height"])

  return <div className={[style.block, ...modifiers].join(" ")}>{children}</div>
}

Block.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  background: PropTypes.string,
  maxWidth: PropTypes.string,
  desktopFlex: PropTypes.bool,
  gutters: PropTypes.bool,
  maxHeight: PropTypes.bool
}

export default Block
